export const menuItems = [
    // fill 
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.report.text',
        icon: 'ri-file-paper-fill',
        link: '/relatorios/ficha'
    },
    {
        id: 4,
        label: 'menuitems.services.text',
        icon: 'ri-service-fill',
        link: '/atendimentos'
    },
    {
        id: 5,
        label: 'menuitems.registers.list.schedule',
        icon: 'ri-calendar-line',
        link: '/cadastros/calendario'
    },
    {
        id: 6,
        label: 'menuitems.registers.text',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 61,
                label: 'menuitems.registers.list.companies',
                icon: 'ri-store-fill',
                link: '/cadastros/empresas'
            },
            {
                id: 62,
                label: 'menuitems.registers.list.register_pacients',
                icon: 'ri-team-fill',
                link: '/cadastros/pacientes'
            },
            {
                id: 63,
                label: 'menuitems.registers.list.profissionals',
                icon: 'ri-team-fill',
                link: '/cadastros/profissionais'
            },
            {
                id: 64,
                label: 'menuitems.registers.list.responsibles',
                icon: 'ri-team-fill',
                link: '/cadastros/responsibles'
            },
            {
                id: 65,
                label: 'menuitems.registers.list.agreement',
                icon: 'ri-team-fill',
                link: '/cadastros/convenio'
            },
            {
                id: 66,
                label: 'menuitems.registers.list.treatment',
                icon: 'ri-team-fill',
                link: '/cadastros/tratamento'
            },
            {
                id: 67,
                label: 'menuitems.registers.list.approach',
                icon: 'ri-team-fill',
                link: '/cadastros/abordagem'
            },
        ]
    },
    {
        id: 7,
        label: 'menuitems.parameters.text',
        icon: 'ri-global-line',
        subItems: [
            {
                id: 71,
                label: 'menuitems.parameters.list.costcenter',
                link: '/parametros/centrocusto',
            },
            {
                id: 72,
                label: 'menuitems.parameters.list.resultcenter',
                link: '/parametros/centroresultado',
            },
            {
                id: 73,
                label: 'menuitems.parameters.list.paymenttypes',
                link: '/parametros/tipospagamento',
            },
            {
                id: 74,
                label: 'menuitems.parameters.list.historiesdefault',
                link: '/parametros/historicopadrao',
            },
        ]
    },

    {
        id: 8,
        label: 'menuitems.users.text',
        icon: 'ri-account-circle-line',
        subItems: [
            {
                id: 81,
                label: 'menuitems.users.list.insert',
                link: '/register'
            },
            {
                id: 82,
                label: 'menuitems.users.list.changepassword',
                link: '/forgot-password'
            },
            {
                id: 83,
                label: 'menuitems.users.list.list',
                link: '/userGetAll'
            }
        ]
    },
]