export const menuItems = [
    {
        id: 4,
        label: 'menuitems.services.text',
        icon: 'ri-service-fill',
        link: '/profissional/atendimentos'
    },
    {
        id: 5,
        label: 'menuitems.registers.list.schedule',
        icon: 'ri-calendar-line',
        link: '/profissional/agenda'
    }
]